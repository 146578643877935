<template>
  <div
    class="x-modal modal -v2 -with-half-size show"
    id="resetPasswordModal"
    tabindex="-1"
    role="dialog"
    data-loading-container=".modal-body"
    data-ajax-modal-always-reload="true"
    data-ajax-modal="/_ajax_/reset-password"
    data-container="#resetPasswordModal"
    aria-modal="true"
  >
    <div
      class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-mobile"
      role="document"
    >
      <div class="modal-content -modal-content">
        <button
          type="button"
          class="close f-1 "
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-times"></i>
        </button>
        <div class="modal-header -modal-header">
          <div class="x-title-modal m-auto">
            ลืมรหัสผ่าน
          </div>
        </div>
        <div class="modal-body -modal-body">
          <div class="x-modal-separator-container ">
            <div class="-top ">
                <div class="text-center d-flex flex-column">
                  <div class="text-center -img-container">
                    <picture
                      ><source
                        type="image/webp"
                        srcset="../../assets/images/ez-slot-ic_register.webp"/>
                      <source
                        type="image/png?v=1"
                        srcset="../../assets/images/ez-slot-ic_register.png?v=1"/>
                      <img
                        alt="สมัครสมาชิก"
                        class="img-fluid -ic-register"
                        width="60"
                        height="60"
                        src="../../assets/images/ez-slot-ic_register.png?v=1"
                    /></picture>
                  </div>

                  <div class="-x-input-icon my-2 flex-column text-center">
                    <picture
                      ><source
                        type="image/webp"
                        srcset="../../assets/images/ez-slot-ic_phone.webp"/>
                      <source
                        type="image/png?v=1"
                        srcset="../../assets/images/ez-slot-ic_phone.png?v=1"/>
                      <img
                        alt="SPINUP"
                        class="-icon"
                        width="15"
                        height="15"
                        src="../../assets/images/ez-slot-ic_phone.png?v=1"
                    /></picture>

                    <input
                      type="text"
                      v-model="forgot_mobile"
                      class="x-form-control form-control"
                      placeholder="เบอร์โทรศัพท์ที่เคยลงทะเบียน"
                      autofocus="autofocus"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                      maxlength="10"
                      autocomplete="on"
                      inputmode="text"
                    />
                  </div>
                  <div class="-x-input-icon my-2 flex-column text-center">
                    <picture
                      >
                      <source
                        type="image/png?v=1"
                        srcset="../../assets/images/ic_library_book.png?v=1"/>
                      <img
                        alt="SPINUP"
                        class="-icon"
                        width="15"
                        height="15"
                        src="../../assets/images/ic_library_book.png"
                    /></picture>

                    <input
                      type="number"
                      v-model="forgot_account" 
                      class="x-form-control form-control"
                      placeholder="เลขที่บัญชี"
                      autocomplete="on"
                      inputmode="text"
                    />
                  </div>
                  กรุณากรอกรหัสผ่านใหม่
                  <div class="-x-input-icon my-2 flex-column text-center">
                    <picture
                      >
                      <source
                        type="image/png?v=1"
                        srcset="../../assets/images/ez-slot-ic-lock-input.png?v=1"/>
                      <img
                        alt="SPINUP"
                        class="-icon"
                        width="15"
                        height="15"
                        src="../../assets/images/ez-slot-ic-lock-input.png?v=1"
                    /></picture>

                    <input
                      type="password"
                      v-model="forgot_password"
                      class="x-form-control form-control"
                      placeholder="รหัสผ่านใหม่"
                      autocomplete="off"
                      inputmode="text"
                    />
                  </div>
                  <div class="-x-input-icon my-2 flex-column text-center">
                    <picture
                      >
                      <source
                        type="image/png?v=1"
                        srcset="../../assets/images/ez-slot-ic-lock-input.png?v=1"/>
                      <img
                        alt="SPINUP"
                        class="-icon"
                        width="15"
                        height="15"
                        src="../../assets/images/ez-slot-ic-lock-input.png?v=1"
                    /></picture>

                    <input
                      type="password"
                      v-model="forgot_password2"
                      class="x-form-control form-control"
                      placeholder="ยืนยันรหัสผ่านใหม่"
                      autocomplete="off"
                      inputmode="text"
                    />
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      @click="forgotPassword()"
                      class="btn -submit btn-primary my-lg-3 mt-0"
                    >
                      ยืนยัน
                    </button>
                  </div>
                </div>
            </div>
            <div class="-bottom ">
              <div class="x-admin-contact text-center ">
                <span class="x-text-with-link-component">
                  <label class="-text-message ">พบปัญหา</label>
                  <a
                    href="https://lin.ee/E8UsllC"
                    class="-link-message "
                    target="_blank"
                    rel="noopener"
                  >
                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import queryString from 'querystring'
import 'firebase/database'
const axios = require('axios').default
// import OAuthRequest from '@team-decorate/src/Entities/OAuthRequest'
// import OAuthResult from '@team-decorate/src/Entities/OAuthResult'
export default {
  name: 'forgotPassword',
  data () {
    return {
      forgot_mobile:'',
      forgot_account:'',
      forgot_password:'',
      forgot_password2:'',
    }
  },
  methods: {
    forgotPassword() {
      if(this.forgot_mobile && this.forgot_account && this.forgot_password && this.forgot_password2){
        if(this.forgot_password.trim() !== this.forgot_password2.trim()){
          this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดตรวจสอบข้อมูลรหัสให้ถูกต้องค่ะ!!!' })
        }else{
          axios({
            method: 'post',
            url: 'https://apifront.spin-up.co/api/forgotpassword',
            data: {
              mobile: this.forgot_mobile.trim(),
              account: this.forgot_account.trim(),
              newpassword: this.forgot_password.trim()
            }
          }).then(async res => {
            if (res && res.data && res.data.status) {
              $("#resetPasswordModal").modal('hide')
              this.$swal({ icon: 'success', title: 'ข้อความ!', text: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ' })
            }else if(res.data.error){
              this.$swal({ icon: 'error', title: 'ข้อความ!', text: res.data.error })
            }
          });
        }
      }else{
        this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดระบุข้อมูลให้ครบด้วยค่ะ!!!' })
      }
    },
  }
}
</script>